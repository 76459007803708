import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import Article, { ArticleType, PriceKey, ProductOptionGroupPivot } from "../../models/menu/Article";
import EanCode, { OracleEanCode } from "../../models/menu/EanCode";
import parseApiTimeTable from "../../api/parseApis/parseApiTimeTable";
import { getDiscounts } from "../../api/parseApis/useParseApiArticle";
import { parseEansFromApiArticle } from "./selectEanCodeMapping";
import { allergensDictionary } from "../../api/parseApis/useParseApiAllergen";
import { selectArticlegroupParentsMap } from "./selectArticlegroupParentsMap";
import { selectArticleArticlegroupsMapUnfiltered } from "./selectArticleArticlegroupsMapUnfiltered";
import { ProductResponse } from "../api/menuDataApi";
import _ from "lodash";
import md5 from "md5";

export const selectArticlesMap = createSelector(
  [
    (state: RootState) => state.menuData.products,
    (state: RootState) => state.global.salesarea,
    (state: RootState) => state.menu.priceKey,
    selectArticlegroupParentsMap,
    selectArticleArticlegroupsMapUnfiltered,
  ],
  (products, salesarea, priceKey, articlegroupParentsMap, articleArticlegroupsMap) => {
    const articlesMap: Record<string, Article> = {};

    const allMediasById = _.chain(products).map("media_urls.imgid").filter().flatMap().keyBy("id").value();

    const parseApiArticle = (apiArticle: ProductResponse) => {
      const articleId = String(apiArticle.id ?? md5(JSON.stringify(apiArticle)));

      const eanCodes: (EanCode | OracleEanCode)[] = Object.values(parseEansFromApiArticle(apiArticle));

      let opties: any = apiArticle.opties;
      if (apiArticle.opties != null && !Array.isArray(apiArticle.opties)) {
        opties = Object.values(apiArticle.opties);
      }
      const optionGroupIds: string[] = [];

      const optieMediaUrlsGroupedByMediaId = _.keyBy(apiArticle.media_urls?.opties, "id");

      opties
        ?.filter(
          (apiOptionGroup: any) => (apiOptionGroup.is_active_in_pos ?? true) && !(apiOptionGroup.disabled ?? false)
        )
        .forEach((apiOptionGroup: any) => {
          optionGroupIds.push(String(apiOptionGroup.id ?? md5(JSON.stringify(apiOptionGroup))));
          apiOptionGroup.optie
            ?.filter(
              (apiOptionItem: any) =>
                (apiOptionItem.actief ?? true) &&
                (apiOptionItem.is_active_in_pos ?? true) &&
                !(apiOptionItem.disabled ?? false)
            )
            .forEach((apiOptionItem: any) => {
              const apiOptionItemId = String(apiOptionItem.id ?? md5(JSON.stringify(apiOptionItem)));

              const addExtraArticleIds: { articleId: string; type: ArticleType }[] = [];
              if (apiOptionItem.depositId) {
                addExtraArticleIds.push({ articleId: String(apiOptionItem.depositId), type: ArticleType.Deposit });
              }

              const priceKeys: Record<string, PriceKey> = {};
              if (apiOptionItem.prices) {
                Object.values(apiOptionItem.prices).forEach((priceObject: any) => {
                  if (priceObject.type == "fixed_amount") {
                    priceKeys[priceObject.id] = priceObject;
                  } else {
                    priceKeys[priceObject.id] = { discountPercentage: priceObject.price / 100 };
                  }
                });
              }

              const article: Article = {
                id: apiOptionItemId,
                articleTraits: {},
                stock: apiOptionItem.stock,
                printName: apiOptionItem.printName ?? null,
                discounts: {},
                menuTagIds: [],
                imageMediaUrls: optieMediaUrlsGroupedByMediaId[apiOptionItem.media_id]
                  ? [optieMediaUrlsGroupedByMediaId[apiOptionItem.media_id]]
                  : allMediasById[apiOptionItem.media_id]
                    ? [allMediasById[apiOptionItem.media_id]]
                    : [],
                price: apiOptionItem.prijs,
                priceKeys: priceKeys,
                apiId1: apiOptionItem.apiId1 ?? "",
                apiId2: apiOptionItem.apiId2 ?? null,
                optionGroupIds: [],
                type: ArticleType.Regular,
                canOrder: true,
                isNotAvailable: false,
                isTip: false,
                name: apiOptionItem.naam,
                description: apiOptionItem.omschrijving,
                originalPrice: apiOptionItem.prijs,
                sortKey: apiOptionItem.sortkey ?? 0,
                blocked: Boolean(apiOptionItem.blocked) ?? false,
                updatedAt: "",
                hashedName: "",
                vaprice: apiOptionItem.vap ? parseFloat(apiOptionItem.vap) : 0,
                propposId: null,
                customData: apiOptionItem.custom_data ? JSON.parse(apiOptionItem.custom_data) : {},
                maxCount: apiOptionItem.maxOrder === 0 ? null : (apiOptionItem.maxOrder ?? null),
                mealConfiguratorLayers: [],
                minCount: apiOptionItem.minCount ?? 0,
                apiData: apiOptionItem,
                translations: JSON.parse(apiOptionItem.translations ?? "{}"),
                requireAge: apiOptionItem.requireAge,
                addExtraArticleIds: addExtraArticleIds,
                defaultCount: apiOptionItem.defaultCount ?? (apiOptionItem.default ? 1 : undefined),
                orderTimes: parseApiTimeTable(apiOptionItem.timeTable),
                productPriceLines: [],
              };
              article.discounts = getDiscounts(priceKey, article);

              articlesMap[apiOptionItemId] = article;
            });
        });

      let price;
      if (salesarea.priceMode === "eatin") {
        price = apiArticle.prijsEatIn;
      } else if (salesarea.priceMode === "delivery") {
        price = apiArticle.prijsDelivery;
      } else if (salesarea.priceMode === "takeaway") {
        price = apiArticle.prijsTakeaway;
      } else if (salesarea.priceMode === "default") {
        price = apiArticle.price;
      } else {
        price = apiArticle.price;
      }
      price = Number(price);
      if (Number.isNaN(price)) {
        if (apiArticle.prijs != null) {
          price = Number(apiArticle.prijs);
        } else {
          price = 0;
        }
      }
      if (Number.isNaN(price)) {
        price = 0;
      }

      let translations = {};
      if (apiArticle.translations) {
        try {
          translations = JSON.parse(apiArticle.translations);
        } catch (e) {
          console.log(e);
        }
      }

      if (apiArticle.freeOptionsRequired && apiArticle.freeOptionsRequired !== "[]") {
        const apiOptionGroup = {
          naam: apiArticle.freeOptionsRequiredTitle ?? "",
          maxselect: 1,
          minselect: 1,
          ids: JSON.parse(apiArticle.freeOptionsRequired).map((id: (string | number)[]) => String(id)),
          id: apiArticle.id,
        };

        const optionGroupId = md5(JSON.stringify(apiOptionGroup));
        optionGroupIds.push(optionGroupId);
      }
      if (apiArticle.freeOptionsRequired2 && apiArticle.freeOptionsRequired2 !== "[]") {
        const apiOptionGroup = {
          naam: apiArticle.freeOptionsRequiredTitle2 ?? "",
          maxselect: 1,
          minselect: 1,
          ids: JSON.parse(apiArticle.freeOptionsRequired2).map((id: (string | number)[]) => String(id)),
          id: apiArticle.id,
        };

        const optionGroupId = md5(JSON.stringify(apiOptionGroup));
        optionGroupIds.push(optionGroupId);
      }
      if (apiArticle.freeOptionsRequired3 && apiArticle.freeOptionsRequired3 !== "[]") {
        const apiOptionGroup = {
          naam: apiArticle.freeOptionsRequiredTitle3 ?? "",
          maxselect: 1,
          minselect: 1,
          ids: JSON.parse(apiArticle.freeOptionsRequired3).map((id: (string | number)[]) => String(id)),
          id: apiArticle.id,
        };

        const optionGroupId = md5(JSON.stringify(apiOptionGroup));
        optionGroupIds.push(optionGroupId);
      }

      const optionGroupOverwrites: { [optionGroupId: string]: ProductOptionGroupPivot } = {};
      apiArticle?.product_option_groups?.forEach((productOptionGroup) => {
        optionGroupIds.push(String(productOptionGroup.option_group_id));
        optionGroupOverwrites[productOptionGroup.option_group_id] = {};
        if (productOptionGroup.sort_key != null) {
          optionGroupOverwrites[productOptionGroup.option_group_id].sortKey = productOptionGroup.sort_key;
        }
        if (productOptionGroup.min_count != null) {
          optionGroupOverwrites[productOptionGroup.option_group_id].minCount = productOptionGroup.min_count;
        }
        if (productOptionGroup.max_count != null) {
          optionGroupOverwrites[productOptionGroup.option_group_id].maxCount = productOptionGroup.max_count;
        }
        if (productOptionGroup.productPrices != null) {
          optionGroupOverwrites[productOptionGroup.option_group_id].productPrices = productOptionGroup.productPrices;
        }
      });

      const tagIds: string[] = apiArticle.product_tags.map((product_tag) => product_tag.tag_id);
      if (apiArticle.allergenen) {
        JSON.parse(apiArticle.allergenen)
          .sort()
          .forEach((allergen: keyof typeof allergensDictionary) => {
            tagIds.push(String(allergen));
          });
      }

      const apiArticleTraits = (apiArticle.custom_data ? JSON.parse(apiArticle.custom_data).articleTraits : {}) ?? {};

      const priceKeys: Record<string, PriceKey> = {};
      if (apiArticle.prices) {
        Object.values(apiArticle.prices).forEach((priceObject: any) => {
          if (priceObject.type == "fixed_amount") {
            priceKeys[priceObject.id] = priceObject;
          } else {
            priceKeys[priceObject.id] = { discountPercentage: priceObject.price / 100 };
          }
        });
      }

      const addExtraArticleIds: { articleId: string; type: ArticleType }[] = [];
      if (apiArticle.depositId) {
        addExtraArticleIds.push({ articleId: String(apiArticle.depositId), type: ArticleType.Deposit });
      }

      const settings = apiArticle.settings ? JSON.parse(apiArticle.settings) : {};

      let articleType = ArticleType.Regular;
      if (apiArticle.fillerProduct) {
        articleType = ArticleType.Filler;
      } else if (settings.isServiceProduct) {
        articleType = ArticleType.ServiceRequest;
      }

      const propposId = apiArticle?.proppos_product?.proppos_product_id ?? null;
      let isDrinksArticle = false;
      let isFoodArticle = false;

      let lastFoundId: string | undefined = undefined;
      (articleArticlegroupsMap[articleId] ?? []).find((menukaartProduct) => {
        let parentId = articlegroupParentsMap[menukaartProduct]?.[0];

        let lastId = parentId;
        const parents = new Set<string>();
        while (parentId && !parents.has(parentId)) {
          lastId = parentId;
          parents.add(parentId);
          parentId = articlegroupParentsMap[parentId]?.[0];
        }

        if (lastId == "drinken") {
          lastFoundId = lastId;
          return true;
        }
        if (lastId == "eten") {
          lastFoundId = lastId;
          return true;
        }
        return false;
      });

      if (lastFoundId == "drinken") {
        isDrinksArticle = true;
      } else if (lastFoundId == "eten") {
        isFoodArticle = true;
      }

      const article: Article = {
        id: articleId,
        apiId1: apiArticle.apiId1 ?? "",
        apiId2: apiArticle.apiId2 ?? null,
        name: apiArticle.naam,
        hashedName: md5(apiArticle.naam + (apiArticle.apiId2 ?? "")),
        isTip: false,
        printName: apiArticle.printName ?? null,
        propposId,
        orderTimes: parseApiTimeTable(apiArticle.timeTable),
        originalPrice: price,
        price: price,
        isDrinksArticle,
        isFoodArticle,
        vaprice: apiArticle.vap ? parseFloat(apiArticle.vap) : 0,
        discounts: {},
        stock: apiArticle.stock,
        description: apiArticle.omschrijving ?? "",
        type: articleType,
        canOrder: true,
        imageMediaUrls: apiArticle.media_urls.imgid ?? [],
        mealConfiguratorStart: apiArticle.media_urls.meal_configurator_start?.[0],
        mealConfiguratorLayer: apiArticle.media_urls.meal_configurator_layer?.[0],
        mealConfiguratorLayers: apiArticle.media_urls.meal_configurator_layers ?? [],
        mealConfiguratorSteps: apiArticle.meal_configurator_steps,
        blocked: Boolean(apiArticle.blocked) ?? false,
        optionGroupIds: optionGroupIds,
        sortKey: apiArticle.sortkey ?? 0,
        menuTagIds: tagIds,
        minCount: apiArticle.minCount ?? 0,
        maxCount: apiArticle.maxOrder === 0 ? null : (apiArticle.maxOrder ?? null),
        articleTraits: apiArticleTraits,
        upsellMenu: apiArticle.upsellMenu ? String(apiArticle.upsellMenu) : undefined,
        addExtraArticleIds: addExtraArticleIds,
        requireAge: apiArticle.requireAge,
        eanCodes: eanCodes,
        translations: translations,
        priceKeys: priceKeys,
        apiData: apiArticle,
        customData: apiArticle.custom_data ? JSON.parse(apiArticle.custom_data) : {},
        defaultCount:
          apiArticle.defaultCount ??
          JSON.parse(apiArticle.custom_data ?? "{}")?.defaultCount ??
          (apiArticle.default ? 1 : undefined),
        updatedAt: "",
        optionGroupOverwrites,
        isNotAvailable: apiArticle.not_available,
        productPriceLines: _.chain(apiArticle.product_price_lines ?? [])
          .keyBy("sales_area_price_line_id")
          .mapValues((data) => data.price_in_cents)
          .value(),
        arrangementGroupProducts: _.keyBy(
          apiArticle.arrangement_group_products?.map((arrangementGroupProduct) => ({
            ...arrangementGroupProduct,
            credit_cost: arrangementGroupProduct.v5_credit_cost,
          })) ?? [],
          "arrangement_group_id"
        ),
      };
      if (article.customData == null) {
        article.customData = {};
      }
      article.customData.bgColor = apiArticle.bgColor;
      article.discounts = getDiscounts(priceKey, article);

      articlesMap[articleId] = article;
    };

    products.forEach(parseApiArticle);

    return articlesMap;
  }
);
