import store from "../redux/store";
import Article from "../models/menu/Article";
import { getArticleById } from "../models/menu/Menu";
import { orderArticlesPushedBySystem } from "../redux/shoppingCartSlice";
import OrderArticle from "../models/order/OrderArticle";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "../../components/FormattedMessageJamezz";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";

export function processOrderCheckerResult(checkResults: any) {
  const orderArticles: OrderArticle[] = store.getState().shoppingCart.items;
  const articlesMap = selectArticlesMap(store.getState());
  const removedProducts: Article[] = [];
  const changedProducts: Article[] = [];

  if (checkResults.productErrors) {
    checkResults.productErrors.forEach((productId: number) => {
      orderArticles.forEach((orderArticle) => {
        if (orderArticle.article.id === String(productId)) {
          const article = getArticleById(articlesMap, String(productId));
          if (article != null) {
            const copyOrderArticle = JSON.parse(JSON.stringify(orderArticle));
            copyOrderArticle.count = -orderArticle.count;
            store.dispatch(orderArticlesPushedBySystem([copyOrderArticle]));
            removedProducts.push(article);
          }
        }
      });
    });
  }

  if (checkResults.priceChangeProducts) {
    checkResults.priceChangeProducts.forEach((productId: number, idx: number) => {
      orderArticles.forEach((orderArticle) => {
        if (orderArticle.article.id === String(productId)) {
          const article = getArticleById(articlesMap, String(productId));
          if (article != null) {
            const copyOrderArticle = JSON.parse(JSON.stringify(orderArticle));
            copyOrderArticle.count = -orderArticle.count;
            store.dispatch(orderArticlesPushedBySystem([copyOrderArticle]));
            changedProducts.push(article);
          }
        }
      });
    });
  }

  if (removedProducts.length > 0 || changedProducts.length > 0) {
    let removedItems = "";
    if (checkResults?.failedProductNames) {
      checkResults?.failedProductNames.forEach((item: string) => {
        removedItems += item + "\n";
      });
      toast.error(
        <FormattedMessageJamezz
          id={"OrderChecker.messages.items-were-removed-with-items"}
          values={{
            removedItems: removedItems,
          }}
        />
      );
    } else {
      toast.error(<FormattedMessageJamezz id={"OrderChecker.messages.items-were-removed"} />);
    }
  }

  const timeslotRules = "CheckTimeslotAvailable";
  if (checkResults?.resultNames && checkResults.resultNames.includes(timeslotRules)) {
    toast.error(<FormattedMessageJamezz id={"Timeslot not available anymore!"} />);
  }
}
