import {
  OptionGroupDto,
  ProductDto,
  VoucherDefAmountOffProduct,
  VoucherDefAmountOffTotal,
  VoucherDefPercentageOffProduct,
  VoucherDefPercentageOffTotal,
  VoucherDefV2,
  VoucherV2,
} from "../vouchersV2Slice.tsx";
import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle.ts";
import Articlegroup from "../../models/menu/Articlegroup.ts";
import _ from "lodash";
import { ArticleType } from "../../models/menu/Article.ts";

export type DiscountsPerOrderArticleUuid = {
  [orderArticleUuid: string]: {
    discount: number;
  }[];
};

function isDiscount(
  value: DiscountsPerOrderArticleUuid | { [p: string]: { discount: number } }
): value is DiscountsPerOrderArticleUuid {
  return Array.isArray(_.chain(value).values().first().value());
}

export function orderArticleOrOptionHasDiscount(
  orderArticle: OrderArticle,
  discounts: DiscountsPerOrderArticleUuid | { [p: string]: { discount: number } }
): boolean {
  return (
    (isDiscount(discounts)
      ? discounts[orderArticle.uuid]?.some((discount) => discount.discount > 0)
      : discounts[orderArticle.uuid]?.discount > 0) ||
    orderArticle.orderOptionGroups.some((orderOptionGroup) =>
      orderOptionGroup.orderArticles.some((option) => orderArticleOrOptionHasDiscount(option, discounts))
    )
  );
}

export function calculateDiscountForVoucher(
  voucher: VoucherV2,
  orderArticles: OrderArticle[],
  priceLineId: number | null,
  currentDiscounts: DiscountsPerOrderArticleUuid,
  articleArticlegroupsMap: Record<string, string[]>,
  articlegroupsMap: Record<string, Articlegroup>,
  multiplier: number = 1
): DiscountsPerOrderArticleUuid {
  const newDiscounts: DiscountsPerOrderArticleUuid = _.chain(orderArticles)
    .keyBy("uuid")
    .mapValues((orderArticle) => _.times(orderArticle.count * multiplier, () => ({ discount: 0 })))
    .value();

  const voucherDef = voucher.voucherdef;

  for (let i = 0; i < voucher.voucher.number_of_times; i++) {
    if (voucherDefIsPercentageOffTotal(voucherDef)) {
      const priceDiscount = voucherDef.priceDiscountPercentage / 100;
      orderArticles.forEach((orderArticle) => {
        for (let i = 0; i < orderArticle.count; i++) {
          const discount = Math.min(
            getTotalPrice(orderArticle, priceLineId, {
              count: 1,
              includeArticleTypes: { [ArticleType.Regular]: true },
            }) * priceDiscount,
            getTotalPrice(orderArticle, priceLineId, {
              count: 1,
              includeArticleTypes: { [ArticleType.Regular]: true },
            }) - currentDiscounts[orderArticle.uuid][i].discount
          );

          currentDiscounts[orderArticle.uuid][i].discount += discount;
          newDiscounts[orderArticle.uuid][i].discount += discount;
        }
      });
    } else if (voucherDefIsAmountOffTotal(voucherDef)) {
      let totalDiscountAmount = voucherDef.priceDiscountAmount;
      orderArticles.some((orderArticle) => {
        for (let i = 0; i < orderArticle.count; i++) {
          const toDeduct = Math.min(
            getTotalPrice(orderArticle, priceLineId, { count: 1 }) - currentDiscounts[orderArticle.uuid][i].discount,
            totalDiscountAmount
          );
          currentDiscounts[orderArticle.uuid][i].discount += toDeduct;
          newDiscounts[orderArticle.uuid][i].discount += toDeduct;
          totalDiscountAmount -= toDeduct;
          if (totalDiscountAmount <= 0) {
            return true;
          }
        }
      });
    } else if (voucherDefIsPercentageOffProduct(voucherDef)) {
      const productDtosById: Record<string, ProductDto | undefined> = _.keyBy(voucherDef.discounted_products, "id");
      const priceDiscount = Number(voucherDef.priceDiscountPercentage) / 100.0;
      let maxChooseItems = voucherDef.maxChooseItems;

      orderArticles
        .filter((orderArticle) => filterProduct(voucher, orderArticle, articleArticlegroupsMap, articlegroupsMap))
        .sort((a, b) => sortOrderArticles(a, b, priceLineId, currentDiscounts))
        .some((orderArticle) => {
          (currentDiscounts[orderArticle.uuid] ?? []).sort((a, b) => a.discount - b.discount);
          const productDto = productDtosById[orderArticle.article.id];
          for (let i = 0; i < orderArticle.count * multiplier; i++) {
            maxChooseItems = productDto?.maxCount ?? maxChooseItems;

            const toDeduct = Math.min(
              getTotalPrice(orderArticle, priceLineId, { count: 1, includeOptions: productDto == null }) *
                priceDiscount,
              getTotalPrice(orderArticle, priceLineId, { count: 1, includeOptions: productDto == null }) -
                currentDiscounts[orderArticle.uuid][i].discount,
              productDto?.maxCount === 0 ? 0 : Number.MAX_VALUE
            );

            currentDiscounts[orderArticle.uuid][i].discount += toDeduct;
            newDiscounts[orderArticle.uuid][i].discount += toDeduct;
            if (maxChooseItems > 0 && toDeduct > 0) {
              maxChooseItems--;
            }

            if (productDto) {
              percentageOffProductRecursive(
                orderArticle,
                productDto.optionGroups ?? [],
                priceLineId,
                multiplier,
                priceDiscount,
                currentDiscounts,
                newDiscounts
              );
            }

            if ((voucherDef.maxChooseItems > 0 || (productDto?.maxCount ?? 0) > 0) && maxChooseItems === 0) {
              return true;
            }
          }
          return voucherDef.maxChooseItems > 0 && maxChooseItems === 0;
        });
    } else if (voucherDefIsAmountOffProduct(voucherDef)) {
      const totalDiscountAmount = Number(voucherDef.priceDiscountAmount);
      let max_discounted_amount =
        voucherDef.max_discounted_amount != null && voucherDef.max_discounted_amount !== 0
          ? voucherDef.max_discounted_amount / 100.0
          : null;

      let maxChooseItems = voucherDef.maxChooseItems;

      orderArticles
        .filter((orderArticle) => filterProduct(voucher, orderArticle, articleArticlegroupsMap, articlegroupsMap))
        .sort((a, b) => sortOrderArticles(a, b, priceLineId, currentDiscounts))
        .some((orderArticle) => {
          (currentDiscounts[orderArticle.uuid] ?? []).sort((a, b) => a.discount - b.discount);

          for (let i = 0; i < orderArticle.count; i++) {
            const toDeduct = Math.min(
              getTotalPrice(orderArticle, priceLineId, { count: 1 }) - currentDiscounts[orderArticle.uuid][i].discount,
              totalDiscountAmount,
              max_discounted_amount === null ? Number.MAX_VALUE : max_discounted_amount
            );

            currentDiscounts[orderArticle.uuid][i].discount += toDeduct;
            newDiscounts[orderArticle.uuid][i].discount += toDeduct;
            if (max_discounted_amount !== null) {
              max_discounted_amount -= toDeduct;
            }

            if (maxChooseItems > 0 && toDeduct > 0) {
              maxChooseItems--;
            }
            if (voucherDef.maxChooseItems > 0 && maxChooseItems === 0) {
              return true;
            }
          }
          return voucherDef.maxChooseItems > 0 && maxChooseItems === 0;
        });
    }
  }

  orderArticles.forEach((orderArticle) => {
    orderArticle.orderOptionGroups.forEach((orderOptionGroup) => {
      const items = orderOptionGroup.orderArticles.filter((orderArticle) => orderArticle.count > 0);
      if (items.length > 0) {
        calculateDiscountForVoucher(
          voucher,
          items,
          priceLineId,
          currentDiscounts,
          articleArticlegroupsMap,
          articlegroupsMap,
          orderArticle.count
        );
      }
    });
  });
  return newDiscounts;
}

// TODO check option in option
function percentageOffProductRecursive(
  orderArticle: OrderArticle,
  optionGroupDtos: OptionGroupDto[],
  priceLineId: number | null,
  multiplier: number,
  priceDiscount: number,
  currentDiscounts: DiscountsPerOrderArticleUuid,
  newDiscounts: DiscountsPerOrderArticleUuid
) {
  optionGroupDtos.forEach((optionGroupDto) => {
    const orderOptionsGroupsById = _.keyBy(orderArticle.orderOptionGroups, "optionGroup.id");
    if (orderOptionsGroupsById[optionGroupDto.id]) {
      let options = optionGroupDto.options;
      if (optionGroupDto.allOptionsCount != null && optionGroupDto.allOptionsCount > 0) {
        options = [...orderOptionsGroupsById[optionGroupDto.id].orderArticles]
          .sort((a, b) => getTotalPrice(b, priceLineId, { count: 1 }) - getTotalPrice(a, priceLineId, { count: 1 }))
          .map((option) => ({
            id: Number(option.article.id),
            maxCount: optionGroupDto.allOptionsCount ?? 1,
          }));
      }

      let maxCount =
        optionGroupDto.maxCount === 0 || optionGroupDto.maxCount == null
          ? Number.MAX_SAFE_INTEGER
          : optionGroupDto.maxCount;

      options?.forEach((optionDto) => {
        const orderOptionsById = _.keyBy(orderOptionsGroupsById[optionGroupDto.id].orderArticles, "article.id");
        if (orderOptionsById[optionDto.id]) {
          for (let i = 0; i < orderOptionsById[optionDto.id].count * multiplier && maxCount > 0; i++) {
            const toDeduct = Math.min(
              getTotalPrice(orderOptionsById[optionDto.id], priceLineId, {
                count: 1,
                includeOptions: false,
              }) * priceDiscount,
              getTotalPrice(orderOptionsById[optionDto.id], priceLineId, {
                count: 1,
                includeOptions: false,
              }) - currentDiscounts[orderOptionsById[optionDto.id].uuid][i].discount
            );

            currentDiscounts[orderOptionsById[optionDto.id].uuid][i].discount += toDeduct;
            if (newDiscounts[orderOptionsById[optionDto.id].uuid] == null) {
              newDiscounts[orderOptionsById[optionDto.id].uuid] = _.times(
                orderOptionsById[optionDto.id].count * orderArticle.count,
                () => ({ discount: 0 })
              );
            }
            newDiscounts[orderOptionsById[optionDto.id].uuid][i].discount += toDeduct;
            if (toDeduct > 0) {
              maxCount--;
            }

            percentageOffProductRecursive(
              orderOptionsById[optionDto.id],
              optionDto.optionGroups ?? [],
              priceLineId,
              multiplier,
              priceDiscount,
              currentDiscounts,
              newDiscounts
            );
          }
        }
      });
    }
  });
}

function filterProduct(
  voucher: VoucherV2,
  orderArticle: OrderArticle,
  articleArticlegroupsMap: Record<string, string[]>,
  articlegroupsMap: Record<string, Articlegroup>
): boolean {
  if (voucherDefIsProductVoucher(voucher.voucherdef)) {
    const articlegroupIds = articleArticlegroupsMap[orderArticle.article.id] ?? [];

    const productIdsMap = _.keyBy(
      _.concat(
        voucher.voucherdef.includedProducts_JSON,
        voucher.voucherdef.applied_on_product_ids_through_menus,
        _.map(voucher.voucherdef.discounted_products, (productDto) => String(productDto.id))
      )
    );

    const productApiId1sMap = _.keyBy(voucher.voucherdef.included_products, "apiId1");
    const supergroups = _.keyBy(voucher.voucherdef.supergroups?.supergroups, "id");

    return Boolean(
      productIdsMap[orderArticle.article.id] ||
        (productApiId1sMap[orderArticle.article.apiId1] &&
          productApiId1sMap[orderArticle.article.apiId1].apiId2 === orderArticle.article.apiId2) ||
        articlegroupIds.some(
          (articlegroupId) =>
            articlegroupsMap[articlegroupId]?.category && supergroups[articlegroupsMap[articlegroupId]?.category]
        )
    );
  } else {
    return false;
  }
}

function voucherDefIsProductVoucher(
  voucherDef: VoucherDefV2
): voucherDef is VoucherDefPercentageOffProduct | VoucherDefAmountOffProduct {
  return voucherDef.discountType == "AMOUNT_OFF_PRODUCT" || voucherDef.discountType == "PERCENTAGE_OFF_PRODUCT";
}

function voucherDefIsPercentageOffProduct(voucherDef: VoucherDefV2): voucherDef is VoucherDefPercentageOffProduct {
  return voucherDef.discountType == "PERCENTAGE_OFF_PRODUCT";
}

function voucherDefIsAmountOffProduct(voucherDef: VoucherDefV2): voucherDef is VoucherDefAmountOffProduct {
  return voucherDef.discountType == "AMOUNT_OFF_PRODUCT";
}
function voucherDefIsAmountOffTotal(voucherDef: VoucherDefV2): voucherDef is VoucherDefAmountOffTotal {
  return voucherDef.discountType == "AMOUNT_OFF_TOTAL";
}
function voucherDefIsPercentageOffTotal(voucherDef: VoucherDefV2): voucherDef is VoucherDefPercentageOffTotal {
  return voucherDef.discountType == "PERCENTAGE_OFF_TOTAL";
}

function sortOrderArticles(
  a: OrderArticle,
  b: OrderArticle,
  priceLineId: number | null,
  currentDiscounts: { [p: string]: { discount: number }[] }
) {
  const bDiscounts = _.sumBy(currentDiscounts[b.uuid], "discount") / b.count;
  const aDiscounts = _.sumBy(currentDiscounts[a.uuid], "discount") / a.count;

  return (
    getTotalPrice(b, priceLineId, { count: 1 }) - bDiscounts - getTotalPrice(a, priceLineId, { count: 1 }) - aDiscounts
  );
}
